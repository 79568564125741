
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Item from "@/components/items/Item.vue";
import range from "lodash/range";
import clone from "lodash/clone";
import debounce from "lodash/debounce";
import Chance from "chance";
import anime from "animejs";

const VICTORY_ITEM_INDEX = 100;
const GENERATED_ITEMS_COUNT = 120;
const chance = new Chance();

@Component({
  components: {
    Item,
  },
})
export default class AppTape extends Vue {
  @Prop({ default: () => [] }) items!: Array<{ price: number }>;
  @Prop({ default: 143 }) itemWidth!: number;
  @Prop({ default: 143 }) itemHeight!: number;
  @Prop({ default: 100 }) boxPrice!: number;
  public animation: any = null;
  public tapeItems: Array<{ price: number }> = [];
  public tapeWidth = 0;
  public infiniteAnimation: any = null;
  public currentItemIndexUnderArrow = -1;
  public $refs: any;

  beforeDestroy() {
    this.stopInfiniteAnimation();
  }

  stopInfiniteAnimation() {
    if (this.infiniteAnimation) {
      this.infiniteAnimation.pause();
      this.infiniteAnimation = null;
    }
  }

  startInfiniteAnimation() {
    this.stopInfiniteAnimation();
    const duration = this.items.length * 500;
    const scrollLeft = this.items.length * this.itemWidth;
    const targets: any = this.$refs.scroller["$el"];
    targets.scrollLeft = 0;
    this.infiniteAnimation = anime({
      easing: "linear",
      targets,
      scrollLeft,
      duration,
      loop: true,
    });
  }

  @Watch("items")
  private onItemsChanged(value?, oldValue?) {
    if (value && value.length && !this.animation) {
      this.generateTapeItems(GENERATED_ITEMS_COUNT);

      if (!oldValue || !oldValue.length) {
        this.startInfiniteAnimation();
      }
    }
  }

  created() {
    const unwatch = this.$watch("tapeWidth", (value) => {
      if (value) {
        this.onItemsChanged(this.items);
        unwatch();
      }
    });
  }

  onResize() {
    this.tapeWidth = this.$el.clientWidth;
  }

  generateTapeItems(count: number = GENERATED_ITEMS_COUNT) {
    const rowsPerTape = Math.ceil(
      this.tapeWidth / (this.items.length * this.itemWidth)
    );
    const rowsCount = rowsPerTape > 1 ? rowsPerTape * 2 : 2;
    const rowsItemsCount = rowsCount * this.items.length;

    if (count < rowsItemsCount) {
      // throw new Error(`generateTapeItems: count < rowsItemsCount`);
      count = rowsItemsCount;
    }

    const maxIndex = this.items.length - 1;
    let lastItemIndex = -1,
      probabilities = this.items.map(({ price }) => {
        return 1 / (price / this.boxPrice);
      }),
      itemsIndexes = range(this.items.length);
    const prevTapeItems = this.tapeItems.slice();
    this.tapeItems = range(rowsItemsCount)
      .map((i) => {
        const index = maxIndex >= i ? i : i % this.items.length;

        return Object.assign(clone(this.items[index]), { index: i });
      })
      .concat(
        range(rowsItemsCount, count).map((i) => {
          const indexes = itemsIndexes.slice();
          const probabilities1 = probabilities.slice();
          if (lastItemIndex >= 0 && itemsIndexes.length > 1) {
            indexes.splice(lastItemIndex, 1);
            probabilities1.splice(lastItemIndex, 1);
          }

          const index = chance.weighted(indexes, probabilities1);

          lastItemIndex = index;
          return Object.assign(clone(this.items[index]), { index: i });
        })
      );
    if (prevTapeItems.length) {
      range(0, 40).forEach((i) => {
        this.tapeItems[i] = Object.assign(
          clone(prevTapeItems[VICTORY_ITEM_INDEX - 20 + i]),
          { index: i }
        );
      });
    }
  }

  async reset() {
    this.currentItemIndexUnderArrow = -1;

    await anime({
      easing: "linear",
      targets: this.$refs["scroller"]["$el"],
      scrollLeft: 0,
      duration: 500,
    }).finished;

    this.startInfiniteAnimation();
  }

  public async skip() {
    if (this.animation) {
      await this.animation.seek(this.animation.duration);
      this.animation = null;
    }
  }

  public async animate(item: { price: number }, duration = 10000) {
    this.stopInfiniteAnimation();
    this.tapeItems[VICTORY_ITEM_INDEX] = Object.assign(clone(item), {
      index: VICTORY_ITEM_INDEX,
    });
    const scrollXOffset = chance.integer({
      min: 0.1 * this.itemWidth,
      max: 0.9 * this.itemWidth,
    });
    const scrollLeft =
      VICTORY_ITEM_INDEX * this.itemWidth - this.tapeWidth / 2 + scrollXOffset;
    const targets: any = this.$refs.scroller["$el"];
    const arrowXPosition = this.tapeWidth / 2;
    this.animation = anime({
      easing: "easeOutCirc",
      targets,
      scrollLeft,
      duration,
      update: ({ progress }) => {
        if (progress < 20) {
          return;
        }
        debounce(() => {
          this.currentItemIndexUnderArrow = Math.floor(
            (targets.scrollLeft + arrowXPosition) / this.itemWidth
          );
        }, 50)();
      },
      complete: () => {
        this.generateTapeItems();
        targets.scrollLeft =
          20 * this.itemWidth - this.tapeWidth / 2 + scrollXOffset;
        this.animation = null;
      },
    });

    return this.animation.finished;
  }
}
